import IconCheckFilled from '@web/packeta-ui-styleguide/icons/status/icon-check-filled.svg'
import IconInfoFilled from '@web/packeta-ui-styleguide/icons/status/icon-info-filled.svg'
import { Animations, GetPacketByIdResponseDataItemPacketStatusId, Status } from '~common/enums'

const packetStatuses = (packetStatusId: GetPacketByIdResponseDataItemPacketStatusId) => {
  const statuses = [
    {
      id: 1,
      finished: packetStatusId == GetPacketByIdResponseDataItemPacketStatusId.WAITING_FOR_DELIVERY,
    },
    { id: 2, finished: packetStatusId == GetPacketByIdResponseDataItemPacketStatusId.ON_THE_WAY },
    {
      id: 3,
      finished: [
        GetPacketByIdResponseDataItemPacketStatusId.READY_FOR_PICKUP,
        GetPacketByIdResponseDataItemPacketStatusId.READY_LAST_DAY,
        GetPacketByIdResponseDataItemPacketStatusId.ISSUED_AND_ACCOUNTED,
      ].includes(packetStatusId),
    },
  ]
  return statuses.map((status, key) => {
    return {
      ...status,
      finished: statuses[key + 1]?.finished || statuses[key + 2]?.finished ? true : status.finished,
    }
  })
}

const packetStatusLottie = (packetStatusId: GetPacketByIdResponseDataItemPacketStatusId) => {
  switch (packetStatusId) {
    case GetPacketByIdResponseDataItemPacketStatusId.WAITING_FOR_DELIVERY:
      return Animations.PACKAGE_WAITING
    case GetPacketByIdResponseDataItemPacketStatusId.ON_THE_WAY:
      return Animations.TRUCK_ZASILKOVNA
    case GetPacketByIdResponseDataItemPacketStatusId.READY_FOR_PICKUP:
    case GetPacketByIdResponseDataItemPacketStatusId.READY_LAST_DAY:
      return Animations.PACKAGE_PIN
    case GetPacketByIdResponseDataItemPacketStatusId.ISSUED_AND_ACCOUNTED:
      return Animations.PACKAGE_SUCCESS
    default:
      return null
  }
}

const packetStatusStepId = (packetStatusId: GetPacketByIdResponseDataItemPacketStatusId) => {
  switch (packetStatusId) {
    case GetPacketByIdResponseDataItemPacketStatusId.WAITING_FOR_DELIVERY:
      return 1
    case GetPacketByIdResponseDataItemPacketStatusId.ON_THE_WAY:
      return 2
    case GetPacketByIdResponseDataItemPacketStatusId.READY_FOR_PICKUP:
    case GetPacketByIdResponseDataItemPacketStatusId.READY_LAST_DAY:
      return 3
    case GetPacketByIdResponseDataItemPacketStatusId.ISSUED_AND_ACCOUNTED:
      return 4
    default:
      return null
  }
}

const getInputStatusAppendIcon = (error: boolean, value?: any) => {
  if (error) {
    return {
      component: IconInfoFilled,
      state: Status.ERROR,
    }
  } else if (value) {
    return {
      component: IconCheckFilled,
      state: Status.SUCCESS,
    }
  }

  return undefined
}

export { packetStatuses, packetStatusLottie, packetStatusStepId, getInputStatusAppendIcon }
